import React, { useState, useEffect } from 'react';
import ProductTable from '../components/ProductTable';
import { productService } from '../api/productService';
import { Product } from '../types/product';

const ProductPage: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedLicence, setSelectedLicence] = useState<string>('');
  const [availableGroups, setAvailableGroups] = useState<{value: string, label: string}[]>([]);
  const [availableTypes, setAvailableTypes] = useState<{value: string, label: string}[]>([]);
  const [availableLicences, setAvailableLicences] = useState<{value: string, label: string}[]>([]);

  const fetchProducts = async (
    page: number, 
    perPage: number, 
    search?: string,
    group?: string,
    type?: string,
    licence?: string
  ) => {
    setIsLoading(true);
    try {
      const { products: fetchedProducts, total } = await productService.fetchProducts(
        page, 
        perPage, 
        search,
        group,
        type,
        licence
      );
      setProducts(fetchedProducts);
      setTotalItems(total);
    } catch (error) {
      console.error('Erreur lors de la récupération des produits:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFilters = async () => {
    try {
      const [groups, types, licences] = await Promise.all([
        productService.fetchProductGroups(),
        productService.fetchProductTypes(),
        productService.fetchProductLicences()
      ]);
      setAvailableGroups(groups);
      setAvailableTypes(types);
      setAvailableLicences(licences);
    } catch (error) {
      console.error('Erreur lors de la récupération des filtres:', error);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchProducts(currentPage, itemsPerPage, searchTerm, selectedGroup, selectedType, selectedLicence);
    }, 300);

    return () => clearTimeout(timer);
  }, [currentPage, itemsPerPage, searchTerm, selectedGroup, selectedType, selectedLicence]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleGroupFilter = (group: string) => {
    setSelectedGroup(group);
    setCurrentPage(1);
  };

  const handleTypeFilter = (type: string) => {
    setSelectedType(type);
    setCurrentPage(1);
  };

  const handleLicenceFilter = (licence: string) => {
    setSelectedLicence(licence);
    setCurrentPage(1);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="font-bold text-xl sm:text-2xl md:text-3xl text-gray-700" style={{ position: 'relative', zIndex: '1' }}>
        Liste des Produits
      </h1>
      <ProductTable 
        products={products}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        isLoading={isLoading}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
        searchTerm={searchTerm}
        onSearch={handleSearch}
        onGroupFilter={handleGroupFilter}
        onTypeFilter={handleTypeFilter}
        onLicenceFilter={handleLicenceFilter}
        availableGroups={availableGroups}
        availableTypes={availableTypes}
        availableLicences={availableLicences}
      />
    </div>
  );
};

export default ProductPage; 