import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaChevronDown, FaChevronUp, FaFileDownload, FaSpinner } from "react-icons/fa";
import { Link, useParams } from 'react-router-dom';
import { downloadService } from '../api/downloadService';
import { orderService } from '../api/orderService';
import { CustomOption, Order } from '../types/order';
import { Tooltip } from 'react-tooltip';
import {productService} from "../api/productService";
import {toast} from "react-toastify";

interface OrderDetailsProps { }

const OrderDetails: React.FC<OrderDetailsProps> = () => {
  const { commandeId } = useParams<{ commandeId: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [openAccordion, setOpenAccordion] = useState<number | null>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDownloading, setIsDownloading] = useState<string | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollableContent = document.getElementById('scrollable-content');
      if (scrollableContent) {
        setIsScrolled(scrollableContent.scrollTop > 0);
      }
    };

    const scrollableContent = document.getElementById('scrollable-content');
    if (scrollableContent) {
      scrollableContent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableContent) {
        scrollableContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const downloadFile = async (fileType: string) => {
    try {
      await downloadService.downloadFile(
        `/download/${commandeId}/${fileType}`,
        `order-${commandeId}.${fileType}`,
        fileType
      );
    } catch (error) {
      // Error handling is done in the service
    }
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!commandeId) return;
      try {
        const fetchedOrder = await orderService.fetchOrderById(commandeId);
        setOrder(fetchedOrder);
      } catch (err) {
        setError('Erreur lors du chargement des détails de la commande');
      }
    };

    fetchOrderDetails();
  }, [commandeId]);

  const renderProductCustomization = (productOptions: any) => {
    const { customOptions, bundleOptions } = productOptions;

    const getOptionSelectionDetails = (selection: any) => {
      const name = selection.name || selection.title || selection.label;
      const id = selection.id || selection.optionId || 'ID inconnu';
      return name ? `${name} (ID: ${id})` : null;
    };

    return (
      <div className="mt-4">
        {customOptions && customOptions.length > 0 && (
          <div className="mb-4">
            <h4 className="text-md font-semibold text-gray-700 mb-2">
              Options personnalisées :
            </h4>
            <ul className="list-disc list-inside space-y-1">
              {customOptions
                .sort((a: CustomOption, b: CustomOption) => a.optionLabel.localeCompare(b.optionLabel))
                .map((option: CustomOption, index: number) => {
                  // TODO URL dynamique suivant le contexte
                  const linkUrl = `https://labelmaker.ludilabel.fr/labelmaker/personalisation-bo-edit?persoId=${encodeURIComponent(option.optionValue)}`;
                  return (
                      <li key={index} className="text-sm text-gray-600">
                        <span className="font-medium text-gray-800">{option.optionLabel} : </span>
                        {option.optionLabel === "labelmaker-personalization-id"
                            ? (
                                <a href={linkUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600">
                                  ✏️ Cliquer pour modifier la perso #{option.optionValue}
                                </a>
                            )
                            : (
                                option.optionValue
                            )
                        }
                        {option.value}
                      </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const renderSubItems = (parentItemId: string) => {
    const subItems = order?.orderItems.filter(item => item.parentItem && item.parentItem['@id'] === parentItemId);
    
    if (!subItems?.length) return null;
    
    return (
      <div className="mt-4">
        <h4 className="text-md font-semibold text-gray-700 mb-2">
          Sous-produits
        </h4>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[60%]">
                  Nom
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[30%]">
                  SKU
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[80px]">
                  Quantité
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {subItems.map((subItem, index) => (
                <tr key={index} className="even:bg-gray-50">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <div 
                      className="truncate"
                      data-tooltip-id="cell-tooltip" 
                      data-tooltip-content={subItem.name}
                    >
                      {subItem.name}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <div 
                      className="truncate"
                      data-tooltip-id="cell-tooltip" 
                      data-tooltip-content={subItem.sku}
                    >
                      {subItem.sku}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500 text-center w-[80px]">
                    {subItem.qtyOrdered}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Tooltip
            id="cell-tooltip"
            place="top"
            className="z-50"
            style={{
              backgroundColor: '#1F2937',
              color: 'white',
              padding: '4px 8px',
              borderRadius: '4px',
              fontSize: '0.875rem',
              maxWidth: '300px'
            }}
          />
        </div>
      </div>
    );
  };

  const toggleAccordion = (index: number) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const handleDownload = async (fileType: string) => {
    setIsDownloading(fileType);
    try {
      await downloadFile(fileType);
    } finally {
      setIsDownloading(null);
    }
  };

  const generateZip = async () => {
    if (!order || !commandeId) return;

    try {
      await orderService.generateZip(commandeId);
      toast.success('ZIP envoyé avec succès!');
    } catch (err) {
      setError('Erreur lors de l\'envoi du ZIP');
      toast.error('Erreur lors de l\'envoi du ZIP');
    }
  };

  const generateXml = async () => {
    if (!order || !commandeId) return;

    try {
      await orderService.generateXml(commandeId);
      toast.success('XML envoyé avec succès!');
    } catch (err) {
      setError('Erreur lors de l\'envoi du XML');
      toast.error('Erreur lors de l\'envoi du XML');
    }
  };

  const generatePdf = async () => {
    if (!order || !commandeId) return;

    try {
      await orderService.generatePdf(commandeId);
      toast.success('PDF envoyé avec succès!');
    } catch (err) {
      setError('Erreur lors de l\'envoi du PDF');
      toast.error('Erreur lors de l\'envoi du PDF');
    }
  };


  if (error) {
    return <div>Erreur: {error}</div>;
  }

  if (!order) {
    return <div>Commande non trouvée</div>;
  }

  return (
    <div className="flex flex-col h-screen">
      {/* En-tête fixe avec ombre conditionnelle */}
      <div 
        className={`bg-white z-10 transition-shadow duration-200 ${
          isScrolled ? 'shadow-lg' : ''
        }`}
      >
        <div className="container mx-auto max-w-7xl p-4">
          <h2 className="text-3xl font-semibold text-gray-900 mb-6">
            Détails de la Commande #{order?.remoteId}
          </h2>

          <div className="flex justify-between items-center">
            <Link to="/commandes" className="flex items-center text-blue-600 hover:text-blue-800">
              <FaArrowLeft className="mr-2" /> Retour à la liste des commandes
            </Link>
            <div className="flex gap-4">
              <div className="flex gap-2">
                <button
                  onClick={() => handleDownload('xml')}
                  disabled={isDownloading === 'xml'}
                  className={`
                    flex items-center gap-2 px-4 py-2 rounded
                    ${isDownloading === 'xml' 
                      ? 'bg-gray-400 cursor-not-allowed' 
                      : 'bg-[#272860] hover:bg-[#1a1b45]'
                    }
                    text-white transition-colors duration-200
                  `}
                >
                  {isDownloading === 'xml' ? (
                    <FaSpinner className="animate-spin" />
                  ) : (
                    <FaFileDownload />
                  )}
                  Télécharger XML
                </button>

                <button
                  onClick={() => handleDownload('pdf')}
                  disabled={isDownloading === 'pdf'}
                  className={`
                    flex items-center gap-2 px-4 py-2 rounded
                    ${isDownloading === 'pdf' 
                      ? 'bg-gray-400 cursor-not-allowed' 
                      : 'bg-[#272860] hover:bg-[#1a1b45]'
                    }
                    text-white transition-colors duration-200
                  `}
                >
                  {isDownloading === 'pdf' ? (
                    <FaSpinner className="animate-spin" />
                  ) : (
                    <FaFileDownload />
                  )}
                  Télécharger PDF
                </button>
              </div>
              <div className="flex gap-2">
                <button
                    onClick={() => {
                      if (window.confirm("Êtes-vous sûr de vouloir renvoyer le XML en production ?")) {
                        generateXml();
                      }
                    }}
                    className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Renvoyer XML en prod
                </button>
                <button
                    onClick={() => {
                      if (window.confirm("Êtes-vous sûr de vouloir renvoyer le PDF en production ?")) {
                        generatePdf();
                      }
                    }}
                    className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Renvoyer PDF en prod
                </button>
                <button
                    onClick={() => {
                      if (window.confirm("Êtes-vous sûr de vouloir renvoyer le PDF en production ?")) {
                        generateZip();
                      }
                    }}
                    className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Renvoyer ZIP en prod
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contenu scrollable */}
      <div 
        id="scrollable-content"
        className="flex-1 overflow-y-auto"
      >
        <div className="container mx-auto max-w-7xl p-4">
          <div className="bg-white shadow-xl rounded-xl p-6 space-y-6">
            {/* Informations générales */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
                <span className="mr-2">📋</span>
                Informations générales
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500 mb-1">ID interne</p>
                  <p className="font-medium">{order.internalId}</p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500 mb-1">Email client</p>
                  <p className="font-medium">{order.customerEmail}</p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500 mb-1">Date de commande</p>
                  <p className="font-medium">{new Date(order.createdAt).toLocaleDateString()} {new Date(order.createdAt).toLocaleTimeString()}</p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500 mb-1">Statut</p>
                  <p className="font-medium">{order.status}</p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500 mb-1">Adresse de livraison</p>
                  <p className="font-medium">
                    {order.shippingAddress?.street}<br />
                    {order.shippingAddress?.postcode} {order.shippingAddress?.city}<br />
                    {order.shippingAddress?.countryId}
                  </p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500 mb-1">Total</p>
                  <p className="font-medium">{order.baseGrandTotal} €</p>
                </div>
              </div>
            </div>

            {/* Articles commandés */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
                <span className="mr-2">🛍️</span>
                Articles Commandés
              </h3>
              {order.orderItems.filter(item => !item.parentItem).map((item, index) => (
                <div key={index} className="mb-4">
                  <div 
                    className="flex justify-between items-center p-4 border rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100" 
                    onClick={() => toggleAccordion(index)}
                  >
                    <div className="flex-1">
                      <span className="font-semibold text-gray-700">{item.name}</span>
                    </div>
                    <div className="flex items-center gap-6 text-gray-600">
                      <span>Qté: {item.qtyOrdered}</span>
                      <span>Prix total: {(item.basePriceInclTax * item.qtyOrdered).toFixed(2)} €</span>
                      {openAccordion === index ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                  </div>
                  {openAccordion === index && (
                    <div className="p-4 border-x border-b rounded-b-lg space-y-2">
                      <p className="text-gray-600">SKU: {item.sku}</p>
                      <p className="text-gray-600">Id Produit: {item.productId}</p>
                      <p className="text-gray-600">Prix unitaire: {item.basePriceInclTax} €</p>
                      <p className="text-gray-600">Taxe: {item.taxAmount} €</p>
                      {item.productOption && renderProductCustomization(item.productOption)}
                      {renderSubItems(item['@id'])}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails; 