export function getAuthHeaders(): HeadersInit {
    const token = localStorage.getItem('token');
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
}

export async function fetchWithAuth(url: string, options: RequestInit = {}): Promise<Response> {
    try {
        const response = await fetch(url, {
            ...options,
            headers: {
                ...getAuthHeaders(),
                ...options.headers,
            },
        });

        if (response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = '/connexion';
            throw new Error('Session expirée');
        }

        return response;
    } catch (error) {
        if (error instanceof Error && error.message === 'Session expirée') {
            throw error;
        }
        console.error('Erreur réseau:', error);
        throw error;
    }
} 