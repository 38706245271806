import { AttributeOptionResponse, Product } from '../types/product';
import API_URL from './config';
import { fetchWithAuth, getAuthHeaders } from './utils';
import IdTransformer from 'utils/idTransformer';

interface PaginatedResponse<T> {
  member: T[];
  totalItems: number;
  view?: {
    first: string;
    last: string;
    next?: string;
    previous?: string;
  };
}

export const productService = {
    fetchProducts: async (
        page: number = 1, 
        itemsPerPage: number = 50,
        search?: string,
        group?: string,
        type?: string,
        licence?: string
    ): Promise<{products: Product[], total: number}> => {
        try {
            let url = `${API_URL}/products?page=${page}&itemsPerPage=${itemsPerPage}`;
            
            if (search) {
                url += `&q=${encodeURIComponent(search)}`;
            }
            if (group) {
                url += `&customAttributes[universal_product_group]=${encodeURIComponent(group)}`;
            }
            if (type) {
                url += `&customAttributes[universal_product_type]=${encodeURIComponent(type)}`;
            }
            if (licence) {
                url += `&customAttributes[universal_product_licence]=${encodeURIComponent(licence)}`;
            }

            const response = await fetchWithAuth(url);
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data: PaginatedResponse<Product> = await response.json();
            const products = data.member.map(product => ({
                ...product,
                id: IdTransformer.getNumericId(product)!
            }));

            return {
                products,
                total: data.totalItems
            };
        } catch (error) {
            console.error('Erreur lors de la récupération des produits:', error);
            throw error;
        }
    },

    fetchProductById: async (id: string): Promise<Product> => {
        try {
            const response = await fetchWithAuth(`${API_URL}/products/${id}`);
            if (!response.ok) throw new Error('Product not found');
            const product = await response.json();
            return {
                ...product,
                id: IdTransformer.getNumericId(product)!
            };
        } catch (error) {
            console.error('Erreur lors de la récupération du produit:', error);
            throw error;
        }
    },

    fetchAttributeOptions: async (): Promise<AttributeOptionResponse> => {
        try {
            const response = await fetchWithAuth(`${API_URL}/attribute_options`);
            return await response.json();
        } catch (error) {
            console.error('Erreur lors de la récupération des options d\'attribut:', error);
            throw error;
        }
    },

    updateProduct: async (id: string, product: Partial<Product>): Promise<Product> => {
        try {
            const response = await fetchWithAuth(`${API_URL}/products/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/merge-patch+json',
                },
                body: JSON.stringify(product)
            });
            if (!response.ok) throw new Error('Update failed');
            const updatedProduct = await response.json();
            return {
                ...updatedProduct,
                id: IdTransformer.getNumericId(updatedProduct)!
            };
        } catch (error) {
            console.error('Erreur lors de la mise à jour du produit:', error);
            throw error;
        }
    },

    deleteProduct: async (id: string): Promise<void> => {
        try {
            const response = await fetchWithAuth(`${API_URL}/products/${id}`, {
                method: 'DELETE'
            });
            if (!response.ok) throw new Error('Delete failed');
        } catch (error) {
            console.error('Erreur lors de la suppression du produit:', error);
            throw error;
        }
    },

    fetchProductGroups: async (): Promise<{value: string, label: string}[]> => {
        try {
            const response = await fetchWithAuth(`${API_URL}/custom-attributes/universal-product-groups`);
            
            if (!response.ok) {
                throw new Error('Failed to fetch product groups');
            }
            return (await response.json())?.member || [];
        } catch (error) {
            console.error('Erreur lors de la récupération des groupes :', error);
            throw error;
        }
    },

    fetchProductTypes: async (): Promise<{value: string, label: string}[]> => {
        try {
            const response = await fetchWithAuth(`${API_URL}/custom-attributes/universal-product-types`);
            
            if (!response.ok) {
                throw new Error('Failed to fetch product types');
            }
            return (await response.json())?.member || [];
        } catch (error) {
            console.error('Erreur lors de la récupération des types :', error);
            throw error;
        }
    },

    fetchProductLicences: async (): Promise<{value: string, label: string}[]> => {
        try {
            const response = await fetchWithAuth(`${API_URL}/custom-attributes/universal-product-licences`);

            if (!response.ok) {
                throw new Error('Failed to fetch product licences');
            }
            return (await response.json())?.member || [];
        } catch (error) {
            console.error('Erreur lors de la récupération des licences :', error);
            throw error;
        }
    }
};