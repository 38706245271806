import React, {useState, useCallback, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaSearch, FaSpinner } from 'react-icons/fa';
import { Order } from '../types/order';
import Pagination from './Pagination';
import CustomSelect, { SelectOption } from './common/CustomSelect';

interface OrderTableProps {
  orders: Order[];
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  isLoading: boolean;
  onPageChange: (page: number) => void;
  onItemsPerPageChange: (itemsPerPage: number) => void;
  searchTerm: string;
  onSearch: (term: string) => void;
  onStatusFilter?: (status: string) => void;
  availableStatuses: {value: string, label: string}[];
}

const OrderTable: React.FC<OrderTableProps> = ({
  orders,
  currentPage,
  itemsPerPage,
  totalItems,
  isLoading,
  onPageChange,
  onItemsPerPageChange,
  searchTerm,
  onSearch,
  onStatusFilter,
  availableStatuses
}) => {
  const [selectedStatus, setSelectedStatus] = useState<string>('');

  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
  };

  const rowVariants = {
    hidden: { opacity: 0, x: -40 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: {
        duration: 0.2
      }
    }
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Gestionnaires de changement pour les filtres
  const handleStatusChange = (value: string) => {
    setSelectedStatus(value);
    onStatusFilter?.(value);
  };

  let statusOptions: SelectOption[] = [
    { value: '', label: 'Tous les statuts' },
    ...availableStatuses.sort((a, b) => a.label.localeCompare(b.label))
  ];

  const itemsPerPageOptions: SelectOption[] = [
    { value: '20', label: '20 par page' },
    { value: '50', label: '50 par page' },
    { value: '100', label: '100 par page' },
    { value: '200', label: '200 par page' }
  ];

  // Populates statusOptions
  useEffect(() => {
    statusOptions = [
      { value: '', label: 'Tous les statuts' },
      ...availableStatuses.sort((a, b) => a.label.localeCompare(b.label))
    ];
  }, [availableStatuses]);

  return (
    <div className="container mx-auto p-4 z-10 relative flex flex-col h-full">
      <div className="mb-4 flex space-x-2">
        <input
          type="text"
          placeholder="Rechercher par email ou ID..."
          value={searchTerm}
          onChange={(e) => onSearch(e.target.value)}
          className="flex-grow appearance-none border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <div className="w-[200px]">
          <CustomSelect
              options={statusOptions}
              value={selectedStatus ? statusOptions.find(option => option.value === selectedStatus) : statusOptions[0]}
              onChange={(value) => handleStatusChange(value)}
              placeholder="Tous les statuts"
              key={`status-select-${availableStatuses.length}`}
          />
        </div>
        <div className="w-[150px]">
          <CustomSelect
            options={itemsPerPageOptions}
            value={itemsPerPageOptions.find(option => Number(option.value) === itemsPerPage)}
            onChange={(value) => onItemsPerPageChange(Number(value))}
            placeholder="Items par page"
            isClearable={false}
          />
        </div>
      </div>

      <div className="mb-4 text-gray-600">
        {totalItems} résultat{totalItems > 1 ? 's' : ''} trouvé{totalItems > 1 ? 's' : ''}
      </div>

      {isLoading ? (
        <div className="flex-grow flex flex-col justify-center items-center">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            className="mb-4"
          >
            <FaSpinner className="h-12 w-12 text-[#272860]" />
          </motion.div>
          <p className="text-gray-600 text-lg">Chargement des commandes...</p>
        </div>
      ) : orders?.length > 0 ? (
        <motion.div 
          className="flex flex-col h-[calc(100vh-250px)]"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="overflow-auto shadow-md rounded-lg flex-grow">
            <table className="min-w-full divide-y divide-gray-200 text-sm">
              <thead className="bg-[#272860] text-white sticky top-0 z-10">
                <tr>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider">ID</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider">N° Commande</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider">N° Source</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider">Date Commande</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider">Email Client</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider">Statut</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider">Total</th>
                  <th className="px-2 py-2 text-center text-sm font-medium uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {orders.map((order, index) => (
                  <motion.tr 
                    key={order.id}
                    variants={rowVariants}
                    initial="hidden"
                    animate="visible"
                    className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                    hover:bg-gray-100 duration-150 ease-in-out`}
                  >
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">
                      <a href={(`/commandes/${order.id}`)} target="_blank">{order.id || ''}</a>
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">
                      {order.remoteId || ''}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">
                      {order.internalId || ''}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">
                      {new Date(order.createdAt).toLocaleDateString()} {new Date(order.createdAt).toLocaleTimeString()}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-900">
                      {order.customerEmail || ''}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap">
                      <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                        ${order.status === 'expedition' ? 'bg-green-100 text-green-800' : 
                          order.status === 'fabrication' ? 'bg-orange-100 text-orange-800' : 
                          order.status === 'generation' ? 'bg-yellow-100 text-yellow-800' : 
                          order.status === 'preparation' ? 'bg-blue-100 text-blue-800' : 
                          order.status === 'erreur' ? 'bg-red-100 text-red-800' : 
                          'bg-red-100 text-red-800'}`}>
                        {order.status || ''}
                      </span>
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">
                      {typeof order.baseGrandTotal === 'number' 
                        ? `${order.baseGrandTotal.toFixed(2)} €`
                        : ''}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-center">
                      <button
                        onClick={() => navigate(`/commandes/${order.id}`)}
                        className="bg-[#272860] text-white px-4 py-2 rounded hover:bg-[#1e1e50] transition duration-150"
                      >
                        Voir détails
                      </button>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4 flex justify-between items-center">
            <div className="text-gray-600">
              {totalItems} résultat{totalItems > 1 ? 's' : ''} trouvé{totalItems > 1 ? 's' : ''}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        </motion.div>
      ) : (
        <div className="flex-grow flex flex-col justify-center items-center">
          <p className="text-gray-600 text-lg">Aucune commande trouvée</p>
        </div>
      )}
    </div>
  );
};

export default OrderTable;
