import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User } from '../types/user';
import Pagination from './Pagination';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import CustomSelect, { SelectOption } from './common/CustomSelect';

interface TablesProps {
  users: User[];
  onEditUser: (user: User) => void;
  onDeleteUser: (userId: number) => void;
}

const UserTable: React.FC<TablesProps> = ({ users, onEditUser, onDeleteUser }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [usersPerPage, setUsersPerPage] = useState<number>(50);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filteredUsers = users.filter(user =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const nextPage = () => setCurrentPage(prev => (prev < totalPages ? prev + 1 : prev));
  const prevPage = () => setCurrentPage(prev => (prev > 1 ? prev - 1 : prev));

  const handleDeleteUser = (userId: number) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")) {
      onDeleteUser(userId);
      toast.success('Utilisateur supprimé avec succès');
    }
  };

  const handleEditUser = (user: User) => {
    onEditUser(user);
  };

  const RoleColors = {
    'ROLE_ADMIN': 'bg-purple-100 text-purple-800 border-purple-200',
    'ROLE_USER': 'bg-blue-100 text-blue-800 border-blue-200'
  };

  const RolesMapping = {
    'ROLE_ADMIN': 'Administrateur',
    'ROLE_USER': 'Utilisateur'
  };

  const rowVariants = {
    hidden: { opacity: 0, x: -40 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: {
        duration: 0.2
      }
    }
  };

  const itemsPerPageOptions: SelectOption[] = [
    { value: '20', label: '20 par page' },
    { value: '50', label: '50 par page' },
    { value: '100', label: '100 par page' },
    { value: '200', label: '200 par page' }
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex space-x-2">
        <input
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-grow appearance-none border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <div className="w-[150px]">
          <CustomSelect
            options={itemsPerPageOptions}
            value={itemsPerPageOptions.find(option => Number(option.value) === usersPerPage)}
            onChange={(value) => setUsersPerPage(Number(value))}
            placeholder="Items par page"
            isClearable={false}
          />
        </div>
      </div>

      <div className="mb-4 text-gray-600">
        {filteredUsers.length} résultat{filteredUsers.length > 1 ? 's' : ''} trouvé{filteredUsers.length > 1 ? 's' : ''}
      </div>

      <div className="bg-white shadow rounded-lg overflow-x-auto">
        <table className="min-w-full whitespace-no-wrap">
          <thead style={{ backgroundColor: '#272860' }}>
            <tr className="text-white text-center">
              <th className="px-5 py-2">Email</th>
              <th className="px-4 py-2">Actif</th>
              <th className="px-4 py-2">Rôles</th>
              <th className="px-4 py-2 text-right pr-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user) => (
              <motion.tr
                key={user.id}
                variants={rowVariants}
                initial="hidden"
                animate="visible"
                className={user.id % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
              >
                <td className="px-4 py-2 text-center">{user.email}</td>
                <td className="px-4 py-2 text-center">
                  {user.active ? "Oui" : "Non"}
                </td>
                <td className="px-4 py-2 text-center">
                  <div className="flex gap-2 justify-center">
                    {(() => {
                      const role = user.roles.includes('ROLE_ADMIN') ? 'ROLE_ADMIN' : 'ROLE_USER';
                      return (
                        <span
                          className={`px-3 py-1 text-sm rounded-full border ${RoleColors[role]}`}
                        >
                          {RolesMapping[role]}
                        </span>
                      );
                    })()}
                  </div>
                </td>
                <td className="px-4 py-2 text-right pr-4">
                  <button
                    onClick={() => handleEditUser(user)}
                    className="mr-2 text-blue-600 hover:text-blue-800 group relative"
                    title="Modifier"
                  >
                    <PencilIcon className="h-5 w-5" />
                    <span className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 right-0 bottom-full mb-2 whitespace-nowrap">
                      Modifier
                    </span>
                  </button>
                  <button
                    onClick={() => handleDeleteUser(user.id)}
                    className="text-red-600 hover:text-red-800 group relative"
                    title="Supprimer"
                  >
                    <TrashIcon className="h-5 w-5" />
                    <span className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 right-0 bottom-full mb-2 whitespace-nowrap">
                      Supprimer
                    </span>
                  </button>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <div className="text-gray-600">
          {filteredUsers.length} résultat{filteredUsers.length > 1 ? 's' : ''} trouvé{filteredUsers.length > 1 ? 's' : ''}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default UserTable; 