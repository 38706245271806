import React, { useState, useEffect } from 'react';
import OrderTable from '../components/OrderTable';
import { orderService } from '../api/orderService';
import { Order } from '../types/order';
import { toast } from 'react-toastify';

const OrderPage: React.FC = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(200);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [availableStatuses, setAvailableStatuses] = useState<{value: string, label: string}[]>([]);

    useEffect(() => {
        fetchFilters();
    }, []);

    useEffect(() => {
        fetchOrders();
    }, [currentPage, itemsPerPage, searchTerm, selectedStatus]);

    const fetchOrders = async () => {
        try {
            setIsLoading(true);
            const response = await orderService.fetchOrders(
                currentPage,
                itemsPerPage,
                searchTerm,
                selectedStatus
            );
            
            if (response) {
                setOrders(response.member);
                setTotalItems(response.totalItems);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des commandes:', error);
            toast.error('Erreur lors du chargement des commandes');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchFilters = async () => {
        try {
            setAvailableStatuses([
                { value: 'preparation', label: 'preparation' },
                { value: 'generation', label: 'generation' },
                { value: 'fabrication', label: 'fabrication' },
                { value: 'expedition', label: 'expedition' },
                { value: 'erreur', label: 'erreur' }
            ]);
        } catch (error) {
            console.error('Erreur lors de la récupération des filtres:', error);
        }
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (newItemsPerPage: number) => {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const handleSearch = (term: string) => {
        setSearchTerm(term);
        setCurrentPage(1);
    };

    const handleStatusFilter = (status: string) => {
        setSelectedStatus(status);
        setCurrentPage(1);
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="font-bold text-xl sm:text-2xl md:text-3xl text-gray-700 mb-6" 
                style={{ position: 'relative', zIndex: '1' }}>
                Liste des Commandes
            </h1>
            <OrderTable 
                orders={orders}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                isLoading={isLoading}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
                searchTerm={searchTerm}
                onSearch={handleSearch}
                onStatusFilter={handleStatusFilter}
                availableStatuses={availableStatuses}
            />
        </div>
    );
};

export default OrderPage; 