import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthResponse } from './types/user';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import UserPage from './pages/UserPage';
import ProductPage from './pages/ProductPage';
import OrderPage from './pages/OrderPage';
import Sidebar from './components/Sidebar';
import OrderDetails from './pages/OrderDetails';
import ProductDetail from './pages/ProductDetail';
import { logoutUser } from './api/auth';
import './index.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('token'));

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLoginSuccess = (data: AuthResponse): void => {
    setIsAuthenticated(true);
    localStorage.setItem('token', data.token);
  };

  const handleLogout = (): void => {
    logoutUser();
    setIsAuthenticated(false);
    window.location.href = '/connexion';
  };

  const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const isAuthenticated = !!localStorage.getItem('token');
    return isAuthenticated ? <>{children}</> : <Navigate to="/connexion" />;
  };

  return (
    <Router>
      {!isAuthenticated ? (
        <Routes>
          <Route path="/connexion" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
          <Route path="*" element={<Navigate to="/connexion" />} />
        </Routes>
      ) : (
        <div className="flex min-h-screen">
          <Sidebar onLogout={handleLogout} />
          <main className="flex-1 overflow-x-hidden">
            <Routes>
              <Route path="/" element={<Navigate to="/accueil" />} />
              <Route path="/accueil" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
              <Route path="/utilisateur" element={<UserPage />} />
              <Route path="/produits" element={<ProductPage />} />
              <Route path="/produits/:productId" element={<ProductDetail />} />
              <Route path="/commandes" element={<OrderPage />} />
              <Route path="/commandes/:commandeId" element={<OrderDetails />} />
            </Routes>
          </main>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
};

export default App; 